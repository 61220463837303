import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Modal} from '@mp/common/modals/Modal';
import React, {useRef} from 'react';
import {updateFileNameService} from '../services';
import {FileInfo} from '../types';

interface EditFileNameModalProps {
    readonly fileToEdit: FileInfo;
    readonly selectedFolder: string;
    readonly handleClose: () => void;
    readonly onUpdate: () => void;
}

export function EditFileNameModal(props: EditFileNameModalProps): JSX.Element {
    const fileNameInput = useRef<HTMLInputElement>();
    const {fileToEdit, selectedFolder, handleClose, onUpdate} = props;
    const {displayName, fullName: oldFileName} = fileToEdit;

    const resolveNewFullName = (newDisplayName: string): string => {
        const {uploaded, extension} = fileToEdit;
        return `${uploaded}_${newDisplayName.trim()}.${extension}`;
    };

    const handleUpdateClick = (): void => {
        const {value} = fileNameInput.current;
        const newFileName: string = resolveNewFullName(value);

        if (newFileName === oldFileName) {
            handleClose();
        } else {
            updateFileNameService({newFileName, oldFileName, folder: selectedFolder}).then(({success}) => success && onUpdate());
        }
    };

    const handleToUpperCaseClick = (): void => {
        fileNameInput.current.value = fileNameInput.current.value.toUpperCase();
    };

    const handleToLowerCaseClick = (): void => {
        fileNameInput.current.value = fileNameInput.current.value.toLowerCase();
    };

    return (
        <Modal size="small" title="Zmiana nazwy pliku" handleClose={handleClose}>
            <TextInput title="Nazwa pliku" defaultValue={displayName} reference={fileNameInput} clearButton={true} />
            <div>
                <Button title="zmień na małe litery" onClick={handleToLowerCaseClick} />
                <Button title="ZMIEŃ NA WIELKIE LITERY" onClick={handleToUpperCaseClick} />
            </div>
            <Button title="Aktualizuj" onClick={handleUpdateClick} />
        </Modal>
    );
}
