import {CommonContext} from '@mp/common/context';
import {Icon, SvgIcon} from '@mp/common/svg';
import {Router} from '@mp/route';
import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {t} from '../i18n';
import {PaymentRow} from '../types';
import styles from './ExpensesTable.module.scss';

export function ExpensesTable(props: {expenses: Array<PaymentRow>; onEditHandler: (row: PaymentRow) => void}): JSX.Element {
    const {context} = useContext(CommonContext);
    const {isEditMode} = context;

    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    <th>Data</th>
                    <th>{t.cost}</th>
                    <th>{t.energy}</th>
                    <th>{t.coldWater}</th>
                    <th>{t.hotWater}</th>
                    <th>{t.heat}</th>
                    {isEditMode && <th />}
                </tr>
                {props.expenses.map((row) => {
                    return (
                        <tr key={row.id}>
                            <td>{row.dateString}</td>
                            <td>
                                <Link to={Router.getUrlToApartmentExpensesPage({id: row.id, year: new Date(row.dateString).getFullYear()})}>
                                    {row.totalCost?.toFixed(2) || `-${row.calculatedTotalCost.toFixed(2)}`}zł
                                </Link>
                            </td>
                            <td>{row.energy.toFixed(1)}</td>
                            <td>{row.coldWater.toFixed(3)}</td>
                            <td>{row.hotWater.toFixed(3)}</td>
                            <td>{row.heat.toFixed(2)}zł</td>
                            {isEditMode && (
                                <td>
                                    <SvgIcon icon={Icon.Pencil} onClick={() => props.onEditHandler(row)} />
                                </td>
                            )}
                        </tr>
                    );
                })}
                <tr>
                    <td className={styles.sum}>Suma:</td>
                    <td>{totalYearCost()}zł</td>
                </tr>
            </tbody>
        </table>
    );

    function totalYearCost(): string {
        return props.expenses
            .map((s) => Math.abs(s.totalCost))
            .reduce((a, b) => a + b)
            .toFixed(2);
    }
}
