import {service} from '@mp/common/api';
import {AxiosResponse} from 'axios';
import {FileInfo} from '../types';

interface LoadFilesServiceResult {
    directory: string;
    files: Array<FileInfo>;
    folders: Array<string>;
    maxFileSizeMB: number;
    allowedExtensions: Array<string>;
}

export function loadFilesService(folder: string): Promise<LoadFilesServiceResult> {
    return service
        .get<LoadFilesServiceResult>('files.load', {data: {folder}})
        .then((response: AxiosResponse<LoadFilesServiceResult>) => response.data);
}
